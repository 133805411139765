import React, {useEffect, useState} from "react";
import {Form, Modal, Schema, Toggle} from "rsuite";
import {useTranslation} from "react-i18next";

import PhoneInput from "../../Form/PhoneInput/PhoneInput";


import "./ProfileContactPerson.sass"
import contactPersonServices from "../../../services/contactPersonServices";
import {ContactPerson} from "../../../interfaces/ContactPerson";

function TextField(props: any) {
    const { fieldName, label, value, setValue, accepter, ...rest } = props;
    return (
        <Form.Group controlId={`${fieldName}-3`} key={`${fieldName}-3`}>
            <Form.ControlLabel>{label}</Form.ControlLabel>
            <Form.Control
                name={fieldName}
                value={value}
                onChange={setValue}
                key={`${fieldName}-4`}
                accepter={accepter} {...rest}
            />
        </Form.Group>
    );
}

const ProfileContactPerson: React.FC<{editContactPersonData: ContactPerson | null, openModal: boolean, handleCloseContactPerson: Function}> = ({editContactPersonData, openModal, handleCloseContactPerson}) => {

    const { t } = useTranslation();
    const [formError, setFormError] = useState({});
    const [errorPhoneVisible, setErrorPhoneVisible] = useState(false);
    const errorField = t('form.requiredFieldMsg')
    const errorEmail = t('form.validEmailAddress')

    let contactPersonModel: ContactPerson = {
        firstName: '',
        middleName: '',
        lastName: '',
        phone: '',
        id: 0
    }

    const lastName = t('form.lastName')
    const firstName = t('form.firstName')
    const middleName = t('form.middleName')

    const { StringType } = Schema.Types;
    const errorSymbols = t('form.errorSymbols')
    const model = Schema.Model({
        firstName: StringType().addRule((value, data) => {
            return /^[A-Za-zА-Яа-яІіЇїЄєҐґ\-'`]+$/.test(value);
        }, errorSymbols).isRequired(errorField),
        lastName:  StringType().addRule((value, data) => {
            return /^[A-Za-zА-Яа-яІіЇїЄєҐґ\-'`]+$/.test(value);
        }, errorSymbols).isRequired(errorField),
        middleName:  StringType().addRule((value, data) => {
            return /^[A-Za-zА-Яа-яІіЇїЄєҐґ\-'`]+$/.test(value);
        }, errorSymbols).isRequired(errorField),
    });

    let phoneMask = editContactPersonData?.phone?.substring(2);

    const [contactDataFirstName, setContactDataName] = useState(editContactPersonData?.firstName)
    const [contactDataLastName, setContactDataLastName] = useState(editContactPersonData?.lastName)
    const [contactDataMiddleName, setContactDataMiddleName] = useState(editContactPersonData?.middleName)
    const [contactDataPhone, setContactDataPhone] = useState(phoneMask)

    const [formValue, setFormValue] = useState({
        firstName: contactDataFirstName,
        lastName: contactDataLastName
    });
    //const [contactDataId, setContactDataId] = useState(editContactPersonData?.id)

    const setContactPhone = (phoneValue: string) => {
        let phone = phoneValue.replace(/[\(\)\-\+\_ ]/g, '');
        if(phone.length != 12){
            setErrorPhoneVisible(true)
        } else {
            setErrorPhoneVisible(false)
        }
        if (phoneValue) setContactDataPhone(phoneValue)
    }

    const addContactPerson = () => {

        if(!contactDataPhone) setErrorPhoneVisible(true)

        if (Object.keys(formError).length == 0 && contactDataFirstName && contactDataLastName && contactDataMiddleName && contactDataPhone){
            contactPersonModel.lastName = contactDataLastName
            contactPersonModel.firstName = contactDataFirstName
            contactPersonModel.middleName = contactDataMiddleName
            let phone = contactDataPhone.replace(/[\(\)\-\+\_ ]/g, '');
            contactPersonModel.phone = phone


            if (editContactPersonData !== null) {
                contactPersonModel.id = editContactPersonData.id
                contactPersonServices.editContactPersons(editContactPersonData.id, contactPersonModel).then(() => {
                    let contactPerson: ContactPerson = {
                        firstName: contactDataFirstName,
                        lastName: contactDataLastName,
                        middleName: contactDataMiddleName,
                        phone: phone,
                        id: editContactPersonData.id}
                    handleCloseContactPerson(contactPerson, false)
                } )
            } else {
                contactPersonServices.addContactPerson(contactPersonModel).then((data) => {
                    let contactPerson: ContactPerson = {
                        firstName: contactDataFirstName,
                        lastName: contactDataLastName,
                        middleName: contactDataMiddleName,
                        phone: phone,
                        id: data}
                    handleCloseContactPerson(contactPerson, true)

                })
            }

        }
    }



    return (
        <Modal open={openModal}
                className={"modal-contact-data"}
               aria-labelledby="modal-title"
               aria-describedby="modal-description"
               onClose={() => handleCloseContactPerson()}>
            <Modal.Header>
                <Modal.Title id="modal-title">{t('profile.ContactPersons')}</Modal.Title>
            </Modal.Header>
            <Modal.Body id="modal-description">
                <Form model={model} formDefaultValue={formValue} onCheck={setFormError} onSubmit={addContactPerson}>
                    <div className={formError.hasOwnProperty('lastName') ? "input-wrapper has-error" : "input-wrapper"}>
                        <TextField fieldName="lastName" label={lastName} value={contactDataLastName} setValue={setContactDataLastName} key={'lastName'} />
                    </div>
                    <div className={formError.hasOwnProperty('firstName') ? "input-wrapper has-error" : "input-wrapper"}>
                        <TextField fieldName="firstName" label={firstName} value={contactDataFirstName} setValue={setContactDataName} key={'firstName'} />
                    </div>
                    <div className="input-wrapper">
                        <TextField fieldName="middleName" label={middleName} value={contactDataMiddleName} setValue={setContactDataMiddleName} key={'middleName'} />
                    </div>
                    <div className={errorPhoneVisible ? "input-wrapper has-error" : "input-wrapper"}>
                        <label>{t('form.phone')}</label>
                        <Form.Group controlId={'contact-data_phone'}>
                            <PhoneInput valueFunction={setContactPhone} valuePhone={contactDataPhone} showError={errorPhoneVisible} />
                        </Form.Group>
                    </div>
                    <button type="submit" className="btn btn-lg">{t('btn.save')}</button>
                </Form>
            </Modal.Body>
        </Modal>
    )
}
export default ProfileContactPerson