import React, {useEffect, useState} from "react";
import ProductCount from "../../components/ProductCount/ProductCount";
import ExchangeRates from "../../components/ExchangeRates/ExchangeRates";
import TotalOrder from "../../components/TotalOrder/TotalOrder";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../hooks";

import {CartOrder, CartProduct} from "../../interfaces/CartProduct";

import {removeFromReserve, updateCountReserve} from "../../slices/reserveSlice"
import CartOrderProducts from "../../components/CartOrderProducts/CartOrderProducts";

const Reserve: React.FC = () => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const productsReserve = useAppSelector<CartOrder>((state) => state.reserve.value);

    let totalPrice = 0;
    let totalProducts = 0;

        productsReserve.orderItems.forEach((product) => {
                totalPrice += product.product.price * product.count
                totalProducts += product.count
            })



    const [productsPrice, setProductsPrice] = useState(totalPrice)
    const [productsCount, setProductsCount] = useState(totalProducts)

    useEffect(() => {
        let totalPrice = 0;
        let totalProducts = 0;
            productsReserve.orderItems.forEach((product) => {
                    totalPrice += product.product.price * product.count
                    totalProducts += product.count
                })
        setProductsPrice(totalPrice)
        setProductsCount(totalProducts)
    }, [productsReserve])

    const activeOrder = useAppSelector<number>(state => state.activeOrder.value)

    const removeProduct = (index: number) => {

            dispatch(removeFromReserve({productId: index, activeOrder: activeOrder}))

    }

    const productCountChange = (productId: number, count: number) => {
            if(count === 0){
                dispatch(removeFromReserve({productId: productId, activeOrder: 0}))
            }
            else {
                dispatch(updateCountReserve({productId: productId, activeOrder: 0, count: count}));
            }
    }

    return (
        <div className={"tabPanelWrapperReserve"}>

            <CartOrderProducts
                order={productsReserve}
                countButton={true}
                removeProduct={removeProduct}
                onProductCountChange={productCountChange}
                missingItems={null}
            />

            {/*<ExchangeRates />

            <TotalOrder totalProductCart={totalProducts} appointment={"reserve"} />

            <button className={"btn btn-lg"} >{t('basket.checkout')}</button>*/}

        </div>
    )
}
export default Reserve;