import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import User from "../interfaces/User";

const initialVal: User = {"id": 0,
    "managerId": null,
    "login": '',
    "firstName": '',
    "lastName": '',
    "fullName": '',
    "middleName": '',
    "email": '',
    "phone": '',
    "language": '',
    "creditLimit": 0,
    "isSendUpdates": false,
    "lastVisit": '',
    "exchangeRate": 0,
    "exchangeRateWS": 0,
    "balance": 0,
    "roles": [],
    "userPermissions": [],
    "tableSettings": {},
    "useNPNameByDefault" : false,
    "npClientName" : '',
    "isPaymentControlEnabled" : false,
    "managers": [{
            "id" : 0,
            "position": 0,
            "name": '',
            "phone": '',
            "email": '',
            "bankDetails": {
                "name": "",
                "accountNumber": "",
                "taxNumber": "",
                "bankNumber": ""
            }
        }
    ]
}

export const userSlice = createSlice({
        name: 'user',
        initialState: {
            value: initialVal,
        },
        reducers: {
            setUser: (state, action: PayloadAction<User>) => {
                state.value = action.payload
            },
            updateMessageStatus: (state, action: PayloadAction<boolean>) => {
                state.value.isSendUpdates = action.payload
            },
            updateUserNPKey: (state, action: PayloadAction<boolean>) => {
                state.value.useNPNameByDefault = action.payload
            }
        }
    }
);

export const {
    setUser,
    updateMessageStatus,
    updateUserNPKey } = userSlice.actions

export default userSlice.reducer